<template>
  <div>
    <div class="row mb-3">
      <div class="col my-auto">
        <h5 class="mb-0 client-tab-heading">Health Planner</h5>
      </div>
      <div class="col-5 text-end">
        <button
          v-if="!showNewPlanner && clientPlanners.length > 0"
          class="btn btn-sm btn-outline-primary float-end"
          @click="showNewPlanner = true"
        >
          <i class="far fa-plus me-1"></i>
          New Health Plan
        </button>
        <button
          v-if="showNewPlanner"
          class="btn btn-sm btn-light float-end"
          @click="showNewPlanner = false"
        >
          <i class="far fa-ban me-1"></i>
          Cancel
        </button>
      </div>
    </div>

    <!--  -->
    <hr class="my-3" />

    <busy :visible="loading" />

    <div v-if="!loading">
      <div
        class="row mt-4"
        v-if="clientPlanners.length === 0 && !showNewPlanner"
      >
        <div class="col">
          <div class="row" v-if="!showNewPlanner">
            <div class="col-8 mx-auto">
              <div class="card bg-light">
                <div class="card-body text-center">
                  <i
                    class="far fa-hand-holding-seedling fa-4x text-primary"
                  ></i>
                  <p>No Health Plans have been created for this client yet.</p>
                  <button
                    class="btn btn-primary"
                    @click="showNewPlanner = true"
                  >
                    <i class="far fa-plus-circle me-1"></i>
                    Create a new Health Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4" v-if="showNewPlanner">
        <div class="col-8 mx-auto my-auto">
          <div class="card bg-light">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col">
                  <p class="alert alert-primary">
                    Choose a name for your new Health Plan
                  </p>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="newPlanner.name"
                      placeholder="Enter a name..."
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <button class="btn btn-light" @click="showNewPlanner = false">
                    <i class="far fa-times-circle me-1"></i>
                    Cancel
                  </button>
                  <button
                    class="btn btn-primary"
                    :disabled="!newPlanner.name.length > 0"
                    @click="saveNewPlanner"
                  >
                    <i class="far fa-save me-1"></i>
                    Create Health Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-else>
        <div class="col">
          <div class="row mb-3">
            <div class="col"></div>
          </div>
          <div class="row mb-3" v-for="planner in clientPlanners">
            <div class="col-8 mx-auto">
              <div
                class="card cursor-pointer bg-light"
                @click="selectPlanner(planner)"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-auto">
                      <i
                        class="
                          fad
                          fa-hand-holding-seedling fa-4x
                          text-primary
                          my-auto
                        "
                      ></i>
                    </div>
                    <div class="col">
                      <span
                        class="badge text-capitalize"
                        :class="
                          planner.status === 'draft'
                            ? 'bg-dark'
                            : 'bg-info'
                        "
                      >
                        {{ planner.status }}
                      </span>
                      <p class="mb-0">
                        {{ planner.name }}
                      </p>
                      <div
                        class="position-absolute"
                        style="top: 0px; right: 7px"
                      >
                        <button
                          class="btn btn-sm btn-outline-danger me-1"
                          @click.stop="deletePlanner(planner)"
                        >
                          <i class="far fa-trash"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-primary me-1"
                          v-if="planner.status === 'draft'"
                          @click.stop="selectPlanner(planner)"
                        >
                          <i class="far fa-pencil"></i>
                          Edit
                        </button>
                        <button
                          class="btn btn-sm btn-primary"
                          v-if="planner.status === 'draft'"
                          @click.stop="shareWithClient(planner)"
                        >
                          <i class="far fa-share"></i>
                          Share with Client
                        </button>
                        <button
                          class="btn btn-sm btn-outline-danger"
                          v-else
                          @click.stop="removeShare(planner)"
                        >
                          <i class="far fa-ban"></i>
                          Stop Sharing
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SHARE MODAL-->
    <div
      class="modal fade"
      id="sharePlanModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="expandedTaskModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title client-tab-heading"
              id="expandedTaskModalTitle"
            >
              Share Health Plan
            </h5>
          </div>
          <div class="modal-body" v-if="planToShare">
            <div class="row mb-2">
              <div class="col">
                <p>
                  You are about to share {{ planToShare.name }}. This will be
                  visible to the client in their client portal.
                </p>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="send_notifications"
                    v-model="sendClientNotifications"
                    :checked="sendClientNotifications"
                  />
                  <label class="custom-control-label" for="send_notifications">
                    Send client notifications
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
              @click="performShare"
            >
              Confirm Share with Client
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user", "client"],
  data() {
    return {
      loading: true,
      clientPlanners: [],
      showNewPlanner: false,
      newPlanner: {
        clientId: this.client.id,
        name: "",
      },
      planToShare: null,
      sendClientNotifications: this.defaultSendClientNotifications(),
    };
  },
  methods: {
    fetchClientPlanners() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner"
        )
        .then(({ data }) => {
          this.clientPlanners = data;
          this.loading = false;
        });
    },
    saveNewPlanner() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/create",
          this.newPlanner
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.showNewPlanner = false;
          this.fetchClientPlanners();
        });
    },
    updateHealthPlan(plan) {
      return this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/update",
          plan
        )
        .then(({ data }) => {
          this.planToShare = null;
          this.$EventBus.$emit("alert", data);
        });
    },
    deleteHealthPlan(plan) {
      return this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/delete",
          plan
        )
        .then(({ data }) => {
          this.fetchClientPlanners();
          this.$EventBus.$emit("alert", data);
        });
    },
    deletePlanner(plan) {
      var confirmed = confirm(
        "Are you sure you wish to delete this Health Plan? You are unable to undo this action."
      );
      if (confirmed) {
        this.deleteHealthPlan(plan);
      }
    },
    shareWithClient(plan) {
      this.planToShare = plan;
      $("#sharePlanModal").modal("show");
    },
    performShare() {
      this.planToShare.status = "shared";
      this.updateHealthPlan(this.planToShare);
    },
    removeShare(plan) {
      var confirmed = confirm(
        "Are you sure you wish to stop sharing this Health Plan? The client will no longer be able to view the Health Plan from their app."
      );
      if (confirmed) {
        plan.status = "draft";
        this.updateHealthPlan(plan);
      }
    },
    selectPlanner(planner) {
      this.$emit("select-planner", planner);
    },
    defaultSendClientNotifications() {
      return (
        this.user.marketing_email_settings.enable_client_system_emails &&
        this.user.marketing_email_settings.enable_health_planner_emails
      );
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("dddd MMMM Do YYYY");
    },
  },
  computed: {},
  mounted() {
    this.fetchClientPlanners();
  },
  components: {},
};
</script>

<style>
#newStartDate {
  display: none;
}
</style>
