<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" ref="libraryModal">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content bg-light">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col">
                <h5 class="mb-0 client-tab-heading">
                  <span v-if="!assignCardMode">Library</span>
                  <span v-else-if="!editCard">Select a card for {{this.selectedDate | formatDateTime}}</span>
                  <span v-else>Edit card</span>
                  <button class="btn btn-primary btn-sm float-end"
                          v-if="!addCard && !editCard"
                          @click="addCard=true">
                    <i class="far fa-plus me-1"></i>
                    Add New Card
                  </button>
                </h5>
              </div>
            </div>
            <div v-if="addCard">
              <div class="row mb-2">
                <div class="col">
                  <input v-model="newTitle" type="text" class="form-control mb-2" placeholder="Title...">
                  <textarea v-model="newDescription" class="form-control mb-2" placeholder="Description (optional)..."></textarea>
                  <div class="row">
                    <div class="col text-end">
                      <button class="btn btn-sm btn-outline-danger me-2" @click="resetAddCard">
                        <i class="far fa-times-circle me-1"></i>
                        Cancel
                      </button>
                      <button class="btn btn-sm btn-primary" @click="saveNewCard">
                        <i class="far fa-save me-1"></i>
                        Save Card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="editCard">
              <div class="row mb-2">
                <div class="col">
                  <input v-model="editCard.title" type="text" class="form-control mb-2" placeholder="Title...">
                  <textarea v-model="editCard.description" class="form-control mb-2" placeholder="Description (optional)..."></textarea>
                  <div class="row">
                    <div class="col text-end">
                      <button class="btn btn-sm btn-outline-danger" @click="editCard=null">
                        <i class="far fa-times-circle me-1"></i>
                        Cancel
                      </button>
                      <button class="btn btn-sm btn-primary me-2" @click="updateEditCard">
                        <i class="far fa-save me-1"></i>
                        Save Card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row mb-3">
                <div class="col">
                  <input type="text" class="form-control me-1 d-inline" placeholder="Search cards..."
                         v-model="searchQry"/>
                </div>
                <div class="col-auto text-end" v-if="searchQry.length > 0">
                  <button class="btn btn-light"
                          @click="cancelSearch">
                    <i class="far fa-times me-1"></i>
                    Clear Search
                  </button>
                </div>
              </div>
              <div class="row mb-2" v-if="cardLibrary.length === 0">
                <div class="col text-center">
                  <i class="far fa-info-circle fa-2x text-primary"></i>
                  <p>No cards found.</p>
                  <button class="btn btn-primary btn-sm"
                          @click="addCard=true">
                    <i class="far fa-plus me-1"></i>
                    Add New Card
                  </button>
                </div>
              </div>
              <div class="row" style="max-height: 60vh; overflow-y: scroll">
                <div class="col-4 mb-3" v-for="plannerCard in cardLibrary">
                  <div class="card"
                  @click="addCardToPlan(plannerCard)">
                    <div class="card-body text-center" style="cursor: move;">
                      <div>
                        <p class="fw-bold overflow-hidden text-nowrap wrap-ellipses">{{plannerCard.title}}</p>
                        <p class="overflow-hidden text-nowrap wrap-ellipses mb-1"
                           :class="plannerCard.description ? '' : 'text-white'">
                          {{plannerCard.description ? plannerCard.description : '_'}}
                        </p>
                      </div>
                      <div class="row">
                        <div class="col text-start">
                          <button class="btn btn-light btn-sm" @click.stop="editCard=plannerCard">
                            <i class="far fa-edit">
                            </i>
                          </button>
                        </div>
                        <div class="col text-end">
                          <button class="btn btn-light btn-sm" @click.stop="deleteLibraryCard(plannerCard)">
                            <i class="far fa-trash">
                            </i>
                          </button>
                        </div>
                      </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" tabindex="-1" role="dialog" ref="viewPlanItemModal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-light" v-if="selectedPlanItem">
          <div class="modal-body">
            <h5 class="client-tab-heading">{{this.selectedPlanItem.health_planner_card.title}}</h5>
            <p v-if="this.selectedPlanItem.health_planner_card.description">{{this.selectedPlanItem.health_planner_card.description}}</p>
          </div>
          <div class="modal-footer" v-if="planner.status === 'draft'">
            <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
 data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"
                    @click="removeCardFromPlan"
            >
              <i class="far fa-times me-1"></i>
              Remove Card from Health Plan
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col my-auto">
        <h5 class="mb-0 client-tab-heading">Health Planner</h5>
      </div>
      <div class="col-5 text-end">
        <button class="btn btn-sm btn-light me-2"
                @click="backToList">
          <i class="far fa-arrow-left me-1"></i>
          Back to List
        </button>
        <button class="btn btn-sm btn-outline-primary"
                @click="showLibraryManager">
          <i class="far fa-books-medical me-1"></i>
          Health Planner Card Library
        </button>
      </div>
    </div>

    <!--  -->
    <hr class="my-3" />

    <div class="row mb-4">
      <div class="col-auto">
        <h5>
          {{planner.name}}
          <span
            class="ms-2 badge text-capitalize"
            :class="planner.status === 'draft' ? 'bg-dark' : 'bg-info'"
          >
            {{planner.status}}
          </span>
        </h5>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <full-calendar
          class="full-sized-calendar"
          ref="fullCalendar"
          defaultView="timeGridWeek"
          height="auto"
          :header="headerOptions"
          :plugins="calendarPlugins"
          :weekends="true"
          :events="calendarEvents"
          :editable="planner.status === 'draft'"
          :event-duration-editable="false"
          :event-resizable-from-start="false"
          :allDayText="'All Day'"
          :selectable="true"
          :event-time-format="eventTimeFormat"
          :first-day="1"
          :min-time="'06:00:00'"
          :slot-duration="'01:00:00'"
          :all-day-slot="false"
          :display-event-time="false"
          :event-text-color="'#ffffff'"
          :event-border-color="'#e84393'"
          :event-background-color="'#e84393'"
          :event-class-name="'fw-bold'"
          @select="handleSelect"
          @eventClick="handleEventClick"
          @eventDrop="handleEventDrop"
          @eventRender="eventRender"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
require("@fullcalendar/core/locales/en-gb.js");
require("@fullcalendar/core/main.css");
require("@fullcalendar/daygrid/main.css");
require("@fullcalendar/timegrid/main.css");
export default {
  props: ["user", "client", "planner"],
  data() {
    return {
      loading: true,
      addCard: false,
      editCard: null,
      cardLibrary: [],
      newTitle: "",
      newDescription: "",
      searchQry: "",
      calendarPlugins: [timeGridPlugin, interactionPlugin],
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        meridiem: false,
        hour12: false,
      },
      headerOptions: {
        left: "prev,next today",
        center: "title",
        right: "timeGridWeek,timeGridDay",
      },
      selectedDate: null,
      assignCardMode: false,
      selectedPlanItem: null,
    };
  },
  methods: {
    fetchCardLibrary() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/fetch-cards?search=" +
            this.searchQry
        )
        .then(({ data }) => {
          this.cardLibrary = data;
        });
    },
    saveNewCard() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/create-card",
          {
            title: this.newTitle,
            description: this.newDescription,
          }
        )
        .then(({ data }) => {
          this.resetAddCard();
          this.fetchCardLibrary();
          this.$EventBus.$emit("alert", data);
        });
    },
    updateEditCard() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/update-card/" +
            this.editCard.id,
          this.editCard
        )
        .then(({ data }) => {
          this.editCard = null;
          this.fetchCardLibrary();
          this.$EventBus.$emit("alert", data);
        });
    },
    cancelSearch() {
      this.searchQry = "";
      this.fetchCardLibrary();
    },
    deleteLibraryCard(card) {
      var confirmed = confirm(
        "Are you sure you wish to delete this card? It will remain on existing planners but you will no longer see it in your Library."
      );
      if (confirmed) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/planner/delete-card/" +
              card.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchCardLibrary();
          });
      }
    },
    addCardToPlan(card) {
      if (this.assignCardMode) {
        return this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/planner/add-card",
            {
              client_health_plan_id: this.planner.id,
              health_planner_card_id: card.id,
              date: this.selectedDate,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            let element = this.$refs.libraryModal;
            $(element).modal("hide");
            this.refreshPlan();
          });
      }
    },
    removeCardFromPlan() {
      return this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/remove-card",
          {
            client_health_plan_item_id: this.selectedPlanItem.id,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          let element = this.$refs.viewPlanItemModal;
          $(element).modal("hide");
          this.refreshPlan();
        });
    },
    refreshPlan() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/" +
            this.planner.id
        )
        .then(({ data }) => {
          this.planner = data;
        });
    },
    resetAddCard() {
      this.addCard = false;
      this.newTitle = "";
      this.newDescription = "";
    },
    backToList() {
      this.$emit("select-planner", null);
    },
    showLibraryManager() {
      this.assignCardMode = false;
      let element = this.$refs.libraryModal;
      $(element).modal("show");
    },
    handleSelect(arg) {
      if (this.planner.status === "draft") {
        var startDate = new Date(arg.start);
        var endDate = new Date(arg.end);
        this.selectedDate = startDate;

        this.assignCardMode = true;
        let element = this.$refs.libraryModal;
        $(element).modal("show");
      }
    },
    handleEventClick(data) {
      console.log(data);

      this.selectedPlanItem = this.planner.items.find((item) => {
        return item.id == data.event.id;
      });

      let element = this.$refs.viewPlanItemModal;
      $(element).modal("show");
    },
    handleEventDrop(data) {
      console.log(data);
      let newDate = data.event.start;

      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/planner/update-card",
          {
            client_health_plan_item_id: data.event.id,
            date: newDate,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.refreshPlan();
        });
    },
    eventRender(event, element) {
      let item = this.planner.items.find((item) => {
        return item.id == event.event.id;
      });

      if (item.client_completed) {
        event.el.innerHTML = event.el.innerHTML.replace(
          event.el.text,
          "<i class='far fa-check-circle mx-2'></i><del>" +
            event.el.text +
            "</del>"
        );
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("dddd MMMM Do YYYY");
    },
    formatDateTime(date) {
      return moment(date).format("dddd LL @ h:mma");
    },
  },
  computed: {
    calendarEvents: function () {
      return this.planner.items.map((obj) => ({
        ...obj,
        title: obj.health_planner_card.title,
        date: moment.utc(obj.date).local().format("YYYY-MM-DD HH:mm:ss"),
        color: obj.client_completed ? "#28a745" : "#9293BC",
      }));
    },
  },
  mounted() {
    this.fetchCardLibrary();
  },
  watch: {
    searchQry: function (val) {
      if (this.searchQry.length > 2 || !this.searchQry) {
        this.fetchCardLibrary();
      }
    },
  },
  components: {
    FullCalendar,
  },
};
</script>

<style>
.fc-title {
  font-size: larger !important;
}
</style>
