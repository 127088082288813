<template>
  <div class="container">
    <div v-if="client">
      <div id="plannerView">
        <list
          v-if="!currentPlanner"
          :client="client"
          :user="user"
          @select-planner="selectPlanner"
        >
        </list>

        <builder
          v-if="currentPlanner"
          :planner="currentPlanner"
          :client="client"
          :user="user"
          @select-planner="selectPlanner"
        >
        </builder>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Builder from "./planner-partials/Builder";
import List from "./planner-partials/List";

export default {
  props: ["clientObj"],
  data() {
    return {
      client: null,
      currentPlanner: null,
    };
  },
  methods: {
    selectPlanner(planner) {
      this.currentPlanner = planner;
    },
    async fetchClientData() {

      if(this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.clientObj ? this.clientObj.id : this.$route.params.id;
    await this.fetchClientData();
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  components: {
    List,
    Builder,
  },
};
</script>

<style>
#newStartDate {
  background-color: #ffffff;
}
</style>
